import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ScrollAnimation from 'react-animate-on-scroll';

function excert(text){
  text = text.replace(/<\/?[^>]+(>|$)/g, "");
  text = text.substring(0,  110) + '...';
  return text;
  }

const Blog = ({ data, pageContext }) => (
  <>
  <Helmet
  title={data.wordpressPage.acf.blog_title_seo} 
  titleTemplate={data.wordpressPage.acf.blog_title_seo}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.blog_description_seo, 
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.blog_title_seo,
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.blog_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.blog_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>
<div className="small-banner-content">
  <div title={data.wordpressPage.acf.blog_banner_img.alt_text} style={{backgroundImage: `url(${data.wordpressPage.acf.blog_banner_img.localFile.url})`}} className="background-small"></div>
      <div className="text-on-banner">  
      <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
        <h2 className='smallbanner-title'>{pageContext.id.split(':')[0]}</h2>
        </ScrollAnimation>
    </div>
    <div className='right-bottom-curve-cont'>
              <ScrollAnimation animateOnce='true' animateIn='fadeInRight' delay='200' duration='0.5'>
                <div className="right-bottom-curve"></div>
              </ScrollAnimation>
            </div>
    </div>
<Layout lang={pageContext.lang} pageInfo={{ pageName: "blog" }}>
    <div className='blog'>
      <Container>
        <div className='title-cont'><h2 className='title'>{data.wordpressPage.acf.blog_title_small}<span className='dot'>.</span></h2></div>
            <Row>
            {data.allWordpressPost.nodes.map((val, index) => 
            <Col md='4' key={index}>
                <div className='post-box'>
                  <div className='img-box'>
                    {pageContext.lang === 'pl' ?
                    <Link to={'/blog/' + val.slug}>
                    <img src={val.featured_media.localFile.url} alt={val.featured_media.alt_text}></img>
                    </Link>
                    :
                    <Link to={'/en/blog/' + val.slug}>
                      <img src={val.featured_media.localFile.url} alt={val.featured_media.acf.alt_en}></img>
                    </Link>
                  }
                    
  
                  </div>
                  <div className='lower-box'>
                    <div className='post-meta'>
                      <ul className='clearfix'>
                        <li>
                          <FontAwesomeIcon className='icons' icon={faClock} />
                          {val.date}
                        </li>
                        <li>
                        <FontAwesomeIcon className='icons' icon={faUserCircle} />
                          {val.author.name}
                        </li>
                      </ul>
                    </div>
                    <h3 className='title'>
                      {pageContext.lang === 'pl' ?
                      <Link to={'/blog/' + val.slug}>{val.title}</Link>
                      :
                      <Link to={'/en/blog/' + val.slug}>{val.title}</Link>
                    }
                      
                    </h3>
                      
                    
                    <div className='text' >
                    { excert(val.content) }
                    </div>
                    
                    <div className='link-box'>
                      {pageContext.lang === 'pl' ?
                        <Link to={'/blog/' + val.slug} className='theme-btn'>
                        <FontAwesomeIcon className='icon-arr' icon={faChevronRight} />
                        </Link>  
                      :
                      <Link to={'/en/blog/' + val.slug} className='theme-btn'>
                      <FontAwesomeIcon className='icon-arr' icon={faChevronRight} />
                      </Link>
                    }
                    </div>
                  </div>
                </div>
              </Col> 
                     
            )}

            </Row>
      </Container>
    </div>
</Layout> 
</>
)

export default Blog
 export const query = graphql`
 query($id: String!, $lang: String!)  {
  wordpressPage(title: {eq: "Blog"}) {
    acf {
      blog_title_seo
      blog_description_seo
      blog_img_seo {
        localFile {
          url
        }
        alt_text
        acf {
          alt_en
        }
      }
      blog_banner_img {
        localFile {
          url
        }
        alt_text
        acf {
          alt_en
        }
      }
      blog_title
      blog_title_small
    }
  }
  allWordpressPost(filter: {acf: {kategorie: {eq: $id}}}){
    nodes {
      content
      date(formatString: "DD MMM", locale: $lang)
      slug
      title
      featured_media {
        localFile {
          url
        }
        alt_text
        acf {
          alt_en
        }
      }
      author {
        name
      }
      excerpt
      acf {
        kategorie
      }
    }
}
}



 `